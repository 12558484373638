<template>
  <div
    class="recycler"
    ref="recycler"
    :class="`${isVisible ? 'visible' : ''}`"
    :style="style"
    @click="onVisible"
    v-bind="bindRecycler({})"
    @mouseenter="onVisible"
  >
    <div class="recycle-inner" ref="slot">
      <iconic v-if="!isVisible && icon" class="recycler-icon" :name="icon" :style="styleIcon" />
      <slot v-if="isVisible"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["icon"],
  data: function() {
    return {
      isVisible: true,
      started: true,
      threshold: [0.0, 0.5],
      bufferTime: 350,
      style: {
        "min-height": "auto",
      },
      styleIcon: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        "font-size": "2rem",
      },
    };
  },
  methods: {
    observe: function(entri) {
      const { target, isIntersecting, intersectionRatio } = entri[0];
      const isVisible = intersectionRatio !== 0 && intersectionRatio !== 1 && isIntersecting;
      if (this.started) {
        this.started = false;
        this.onVisible({ target });
      } else if (isVisible) {
        this.onVisible({ target });
      } else {
        this.onHidden({ target });
      }
    },
    onVisible: function({ target } = {}) {
      if (target) this.setTargetProps({ target });
      this.isVisible = true;
      this.$emit("visible");
    },
    onHidden: function({ target } = {}) {
      if (target) this.setTargetProps({ target });
      this.isVisible = false;
      this.$emit("hidden");
    },
    setTargetProps: function({ target }) {
      let height = target.offsetHeight;
      let slot = this.$refs.slot;

      if (slot) {
        height = slot.offsetHeight;
      }

      this.style["min-height"] = height + "px";
    },
    recycler: function() {
      const observer = new IntersectionObserver(this.observe, {
        threshold: this.threshold,
      });
      observer.observe(this.$refs.recycler);
    },
    bindRecycler: async function(data) {
      await this.sleep(this.bufferTime);

      const element = this.$refs.recycler;

      if (!element) {
        return;
      }

      const onViewport = this.elementInViewport(element);

      if (onViewport) {
        this.onVisible(this.$refs.recycler);
      }
    },
    elementInViewport: function(el) {
      var top = el.offsetTop;
      var left = el.offsetLeft;
      var width = el.offsetWidth;
      var height = el.offsetHeight;

      while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
      }

      return (
        top >= window.pageYOffset &&
        left >= window.pageXOffset &&
        top + height <= window.pageYOffset + window.innerHeight &&
        left + width <= window.pageXOffset + window.innerWidth
      );
    },
    sleep: function(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    update: function() {
      this.setTargetProps({ target: this.$refs.slot });
      this.bindRecycler();
    },
  },
  mounted: function() {
    this.started = true;
    this.recycler();
    setTimeout(() => {
      this.onVisible(this.$refs.recycler);
    }, this.bufferTime);
  },
};
</script>
